








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ListingAddButton extends Vue {
    @Prop() private readonly text!: string;
    @Prop() private readonly route!: string;

    private onClick() {
        if (!this.hasRoute) {
            return this.$emit('onClick');
        }
        this.$router.push(this.route);
    }

    get hasRoute() {
        return Boolean(this.route);
    }
}
