





















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignListingData } from '../domain/CampaignListingData';
import { CampaignSearchCriteria } from '../domain/CampaignSearchCriteria';

@Component
export default class CampaignTable extends Vue {
    @Prop() private readonly campaigns!: CampaignListingData[];
    @Prop() private readonly isLoading!: any;
    @Prop() private searchCriteria!: CampaignSearchCriteria;
    @Prop() private readonly pageCount!: number;

    private search() {
        this.$emit('updateSearch', this.searchCriteria);
    }

    private updateRouteFor(campaign: CampaignListingData) {
        this.$router.push(`/view-campaign/${campaign.id}`);
    }

    private onPageChange(newPage: number) {
      this.$emit('updatePage', newPage - 1); // Lors d'un click sur la page 1, on doit retourner la page 0
    }
}
