import { configuration } from '@/config';
import { ApiInstance } from '@/api/ApiInstance';
import { Id } from '@/modules/user/domain/Types';
import { Token } from '@/modules/session/domain/Types';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
import { AdvertiserCampaignResponse } from '@/modules/advertiser/rest/response/AdvertiserCampaignResponse';
import { CampaignListingDataResponse } from '../rest/response/CampaignListingDataPagedResponse';
import { CampaignSearchCriteria } from '../domain/CampaignSearchCriteria';
// @ts-ignore
import { CreateCampaignRequest } from '../rest/request/CreateCampaignRequest';
import { Campaign } from '../domain/Campaign';

export class CampaignService {
    private static readonly api = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/campaign`);

    public static async save(campaign: Campaign, token: Token) {
        await this.api.put('/', campaign, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async getCampaignBy(id: Id, token: Token) {
        const { data: campaign } = await this.api.get<Campaign>(`/${id}`, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
        return campaign;
    }

    public static async createCampaign(campaign: Campaign, token: Token) {
        const { data: response } = await this.api.post('/', campaign, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return  response;
    }

    public static async getCampagnesFor(searchCriteria: CampaignSearchCriteria, token: Token) {
        const searchParams = this.buildSearchParamFrom(searchCriteria);
        const { data: response } = await this.api
            .get<CampaignListingDataResponse>(`/?${searchParams}&page=${searchCriteria.page}&sort=id,desc`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        return { campaigns: response._embedded.campaigns, pageCount: response.page.totalPages };
    }

    public static async getActiveCampaignListingFor(advertiserId: Id, page: number, token: Token) {
        const { data: activeCampaignResponse } = await this.api
            .get<AdvertiserCampaignResponse>(`/?search=state:ACTIVE,advertiser:${advertiserId}&size=7&page=${page}`,
            {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        return { campaigns: activeCampaignResponse._embedded.campaigns,
                pageCount: activeCampaignResponse.page.totalPages};
    }

    public static async getInactiveCampaignListingFor(advertiserId: Id, page: number, token: Token) {
        const { data: activeCampaignResponse } = await this.api.get<AdvertiserCampaignResponse>
            (`/?search=state:INACTIVE,advertiser:${advertiserId}&size=7&page=${page}`,
             {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        return { campaigns: activeCampaignResponse._embedded.campaigns,
                pageCount: activeCampaignResponse.page.totalPages};
    }

    private static buildSearchParamFrom(searchCriteria: CampaignSearchCriteria) {
        return `search=${searchCriteria.name ? 'name:'.concat(searchCriteria.name) + ',' : ''}` +
                `${searchCriteria.advertiserName ?
                    'advertiserName:'.concat(searchCriteria.advertiserName) + ',' : ''}` +
                `${searchCriteria.state && searchCriteria.state !== 'Tous' ?
                    'state:'.concat(searchCriteria.state) + ',' : ''}` +
                `${searchCriteria.startDate ? 'startDate>'.concat(searchCriteria.startDate) + ',' : ''}` +
                `${searchCriteria.endDate ? 'endDate<'.concat(searchCriteria.endDate) : ''}`;
    }
}
