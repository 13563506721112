
















import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import ListingAddButton from '@/components/ListingAddButton.vue';
import CampaignTable from '../components/CampaignTable.vue';
import { CampaignListingData } from '../domain/CampaignListingData';
import { CampaignService } from '../services/CampaignService';
import { CampaignSearchCriteria } from '../domain/CampaignSearchCriteria';

@Component({
    components: {Card, Page, ListingAddButton, CampaignTable},
})
export default class CampaignListing extends Vue {
    private campaigns: CampaignListingData[] = [];
    private pageCount: number = 0;
    private isLoading: boolean = false;
    private searchCriteria: CampaignSearchCriteria = {
        page: 0,
        name: '',
        advertiserName: '',
        state: '',
        startDate: '',
        endDate: '',
    };

    private async mounted() {
        await this.fetchCampaignsWithCriteria();
    }

    private async fetchCampaignsWithCriteria() {
        this.isLoading = true;
        try {
            const { campaigns, pageCount } = await CampaignService
                .getCampagnesFor(this.searchCriteria, this.$store.getters['sessionStore/token']);
            this.campaigns = campaigns;
            this.pageCount = pageCount;
        } catch {
            this.campaigns = [];
            this.pageCount = 1;
        }
        this.isLoading = false;
    }

    private async onPageChange(newPage: number) {
        this.searchCriteria.page = newPage;
        await this.fetchCampaignsWithCriteria();
    }

    private async onSearchCriteriaUpdate(searchCriteria: CampaignSearchCriteria) {
        this.searchCriteria = searchCriteria;
        await this.fetchCampaignsWithCriteria();
    }
}
